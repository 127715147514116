.rdg-header-row {
  background-color: white;
}

.rdg-cell {
  background-color: white !important;
  border-bottom: solid 1px #CBCBCD !important;
}

.rdg-cell:has(*[data-locked="true"]) {
  background-color: rgba(244, 184, 99, 0.30) !important;
}

.rdg-cell.rdg-editor-container {
  overflow: visible;
  contain: none !important;
}

.rdg-row {
  contain: none !important;
  overflow: visible;
}

.rdg-table {
  max-height: 100%;
  border: none !important;
  box-sizing: content-box;
  user-select: auto;
}

.rdg-table .rdg-cell {
  border: none;
  border-right: none !important;
  box-sizing: border-box;
  text-overflow: unset;
  display: flex;
  align-items: center;
}

.rdg-table .rdg-cell.rdg-editor-container {
  padding: 0 8px;
}

.rdg-cell-frozen-last {
  box-shadow: none;
}

.rdg-cell-frozen-last::after {
  transition: box-shadow .3s ease-in-out;
  box-shadow: none;
}

/* GooglePlacesAutocomplete FlatList has an extra space below, we need to remove it */
#places-autocomplete-container > div > :nth-child(2) > div:empty:nth-last-of-type(2) {
  display: none;
}

#select-container > div:empty:nth-last-of-type(2) {
  display: none;
}

.modular-table-cell-error::before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 2px;
  bottom: 1px;
  left: 2px;
  right: 2px;
  border: solid 2px #ec5f4f;
  background-color: #ec5f4f29;
  border-radius: 4px;
}
