html,
body,
#root {
  font-family: 'Apercu', 'sans-serif';
  height: 100%;
}

video {
  display: block;
}

/* We need to keep outline on .rdg-cell (for react-data-grid) */
div:not(.rdg-cell):focus,
input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}
