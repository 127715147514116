@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: 500;
  src: url('../../../../../packages/ui/src/assets/icons/icomoon.ttf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: normal;
  font-weight: normal;
  src: url('./apercu-regular.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: italic;
  font-weight: normal;
  src: url('./apercu-italic.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: normal;
  font-weight: 500;
  src: url('./apercu-medium.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: italic;
  font-weight: 500;
  src: url('./apercu-medium-italic.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: normal;
  font-weight: 300;
  src: url('./apercu-light.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: italic;
  font-weight: 300;
  src: url('./apercu-light-italic.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: normal;
  font-weight: 700;
  src: url('./apercu-bold.otf');
}

@font-face {
  font-family: 'Apercu';
  font-display: 'swap';
  font-style: italic;
  font-weight: 700;
  src: url('./apercu-bold-italic.otf');
}

/* Font-weight fallbacks */

@font-face {
  font-family: 'Apercu-Light';
  font-style: normal;
  font-weight: normal;
  src: url('./apercu-light.otf');
}

@font-face {
  font-family: 'Apercu-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('./apercu-bold.otf');
}

@font-face {
  font-family: 'Apercu-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('./apercu-medium.otf');
}
