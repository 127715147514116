@import url("./fonts.css");
@import url("./reset.css");
@import url("./toastr.css");
@import url("./maps.css");

.firebase-emulator-warning {
  opacity: 0.15;
  pointer-events: none;
  width: 200px !important;
  border: none !important;
}

.no-button {
  cursor: pointer;
  background-color: unset;
  outline: unset;
  border: unset;
  font-size: unset;
  color: unset;
}

/* #highlight-focus:focus-visible, */
*[data-focusable="true"]:focus-visible {
  box-shadow: 0px 0px 0px 2px #2546cf inset;
}

.force-pointer-events {
  pointer-events: all !important;
}
